<template>
    <div>
      <v-card flat style="background-color: var(--v-background-base) !important">
        <v-toolbar
          color="greyBase"
          dark
          :extended="!$vuetify.breakpoint.mobile"
          flat
          extension-height="55"
        >
          <v-toolbar-title>
            <b class="mr-3">Intermodal</b>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-btn icon :class="{ 'flicker-icon': flicker }"> -->
            <!-- <v-chip icon outlined :color="iconColor" class="pr-2">
              <span class="pr-2" style="color: var(--v-primaryText-base)">Navis</span>
               <v-icon small :color="iconColor" :class="{ 'flicker-icon': flicker }">radio_button_checked</v-icon>
              </v-chip> -->
            <!-- <v-icon :color="iconColor" :class="{ 'flicker-icon': flicker }">radio_button_checked</v-icon> -->
          <!-- </v-btn> -->
          <v-btn-toggle
            mandatory
            outlined
            :light="!$store.state.darkMode"
            v-model="toggleView"
            rounded
            color="blue"
          >
            <v-btn small outlined>
              <v-icon left small :color="toggleView == 0 ? 'blue' : 'primaryText'"
                >local_shipping</v-icon
              >
              <span style="text-transform: none; color: var(--v-primaryText-base)">Loads</span>
            </v-btn>

          </v-btn-toggle>
        </v-toolbar>
        <v-card
          class="mx-auto"
          :max-width="!$vuetify.breakpoint.mobile ? '94vw' : '100%'"
          :style="{
            'margin-top': !$vuetify.breakpoint.mobile ? '-64px' : '15px',
            'border-radius': !$vuetify.breakpoint.mobile ? '50px' : '0px',
          }"
        >
          <v-card-text
            :style="{
              height: !$vuetify.breakpoint.mobile ? '90vh' : '90vh',
              'overflow-y': 'auto',
            }"
          >
          <v-expand-transition>
              <div v-if="toggleView == 0">
                <ManageTransport />
              </div>
            </v-expand-transition>
          </v-card-text>
        </v-card>
      </v-card>
  
    </div>
</template>
  
  <script>
  
  import ManageTransport from "../components/Bookings/ManageTransport.vue";
  export default {
    components: {
      ManageTransport,
    },
    data: () => ({
      toggleView: 0,
      flicker: true,
      iconColor: 'black',
    }),
    watch: {
  
    },
    computed: {
    
    },
    created() {
    },
    // async mounted() {
    //   await this.getNavisStatus();
    //   setInterval(async () => {
    //      await this.getNavisStatus();
    //      this.flicker = !this.flicker;  
    //   }, 60000);


    // },

    beforeDestroy() {
      clearInterval(this.flicker);
      clearInterval(this.iconColor);
    },
    methods: {
    //   async getNavisStatus(){
    //     let result = await this.$API.getTransnetStatus();
    //     if(result.isAlive){
    //       if(this.$store.state.darkMode){
    //         this.iconColor = 'success'
    //       }
    //       else{
    //         this.iconColor = 'yellow'
    //       }
    //     }
    //     else{
    //       this.iconColor = 'error'

    //     }
        
    //   }
    },
  };
  </script>
  
  <style>
.flicker-icon {
  animation: flickerAnimation 2s infinite;
}

@keyframes flickerAnimation {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}
</style>
  